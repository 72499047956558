import React from 'react';
import style from './modal.module.css';

const Modal = (props) => (
  <div className={style.container}>
    <div className={style.bg} onClick={props.onClose} role="presentation"></div>
    {props.children}
  </div>
);

export default Modal;
