import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Link from 'components/Link';
import gs from 'pages/pages.module.css'
import s from 'pages/homeStyles.module.css'
import WL from 'constants/whiteLabel';
import Img from 'components/Image';
import hexColoredSmall from "utils/images/hex-colored-small.svg"

const OneSolution = () => (
    <section className={cx(gs.content, s.hiveosContent)}>
        {
          WL.showHexImg ? (
            <img alt="hex" className={cx(s.drop1, s.__oneSolution)} src={hexColoredSmall} />
          ) : null
        }
        <div className={gs.backgroundHeader}>deploy</div>
        <div className={cx(gs.wrapper, s.wrapper)}>
            <div className={gs.relative}>
                <div className={s.transfromToLeft}>
                  <Img src="oneSolution.png" className={s.screen} alt="one solution" />
                </div>
            </div>
            <h2>
                <FormattedMessage id="one_solution" defaultMessage="One solution for all systems" />
            </h2>
            <h3 className={gs.subheader}>
                <FormattedMessage id="deploy_in_a_single_click" defaultMessage="Deploy in a single click" />
            </h3>
            <p>
                <FormattedMessage
                    id="one_solution_description"
                    defaultMessage="A {installLink} and setup tool. Download and install our software and your farm infrastructure will automatically detect your mining rigs and add them to your management dashboard"
                    values={{ installLink: <Link withLang to="/install" className={gs.link}><FormattedMessage id="simple_to_install" defaultMessage="simple to install" /></Link> }}
                />
            </p>
            <div className={s.moreLinks}>
                <Link external noRef href={WL.getRegisterLink()} className="btn-primary-simple" target={WL.regLinkTarget}>
                    <FormattedMessage id="deploy_now" defaultMessage="Deploy Now" />
                </Link>
            </div>
        </div>
    </section>
)

export default OneSolution;
