import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Img from 'components/Image';
import Link from 'components/Link';
import hexColoredSmall from "utils/images/hex-colored-small.svg"
import play from 'utils/images/google-play.svg';
import apple from 'utils/images/apple.svg';
import gs from 'pages/pages.module.css'
import s from 'pages/homeStyles.module.css'
import styles from './MobileApp.module.css';

const MobileApp = () => (
    <section className={cx(gs.content, s.hiveosContent, s.alternate, styles.root)}>
        <img alt="hex" className={cx(s.drop1, s.__notifi)} src={hexColoredSmall} />
        <div className={cx(gs.backgroundHeader, styles.bg)}>mobile</div>
        <div className={cx(gs.wrapper, s.wrapper)}>
        <div className={gs.relative}>
          <div className={cx(s.notifiToRight, s.notifi1, styles.imgWrap)}>
            <Img src="app.png" className={cx(s.screen, gs.boxShadow, styles.img)} alt="app image"/>
          </div>
        </div>
        <h2>
          <FormattedMessage id="manage_move" defaultMessage="Manage on the move" />
        </h2>
        <h3 className={gs.subheader}>
          <FormattedMessage id="with_mobile_apps" defaultMessage="with our mobile apps" />
        </h3>
        <p>
          <FormattedMessage id="manage_move_description" defaultMessage="All web platform functionality and much more platform specific features" />
        </p>
        <div className={cx(s.moreLinks, styles.btns)}>
          <Link
            external
            href="https://play.google.com/store/apps/details?id=hiveos.mobile"
            className={cx("btn-primary-simple", styles.btn)}
          >
            <img alt="google play icon" width={24} height={24} src={play} className={cx(styles.ico)} />
            Google Play
          </Link>
          <Link
            external
            href="https://apps.apple.com/us/app/id1521697503"
            className={cx("btn-primary-simple", styles.btn2)}
          >
            <img alt="apple icon" width={24} height={24} src={apple} className={cx(styles.ico2)} />
            App Store
          </Link>
        </div>
        </div>
    </section>
)

export default MobileApp;
