import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import WL from 'constants/whiteLabel';
import Link from 'components/Link';
import Img from 'components/Image';
import hexColoredSmall from 'utils/images/hex-colored-small.svg';

import gs from 'pages/pages.module.css';
import s from 'pages/homeStyles.module.css';

const EasyOperate = () => (
  <section className={cx(gs.content, s.hiveosContent)}>
    {
      WL.showHexImg ? (
        <img alt="hex" className={cx(s.drop1, s.__manage)} src={hexColoredSmall} />
      ) : null
    }
    <div className={gs.backgroundHeader}>manage</div>
    <div className={cx(gs.wrapper, s.wrapper)}>
    <div className={gs.relative}>
      <div className={s.transfromToLeft}>
        <Img src="EasyToOperate.png" className={s.screen} alt="easy to operate"/>
      </div>
    </div>
    <h2>
      <FormattedMessage id="easy_operate" defaultMessage="Easy to operate" />
    </h2>
    <h3 className={gs.subheader}>
      <FormattedMessage id="fine_tune_workers" defaultMessage="Fine-tune your mining workers" />
    </h3>
    <p>
      <FormattedMessage
        id="fine_tune_workers_description"
        defaultMessage="Manage and configure each rig individually whether it’s one or several thousands. Switch pools, wallets and coins combinations on the fly with {flightSheetsLink}. Create {overclockingProfilesLink} for your GPUs and change miner configs across all your farm in seconds"
        values={
          {
            flightSheetsLink: <Link withLang to="/features/#fs" className={gs.link}><FormattedMessage id="flight_sheets_link" defaultMessage="Flight Sheets" /></Link>,
            overclockingProfilesLink: <Link withLang to="/features/#gpuOverclocking" className={gs.link}><FormattedMessage id="overclocking_profiles" defaultMessage="overclocking profiles" /></Link>
          }
        }
      />
    </p>
    <div className={s.moreLinks}>
      <Link external noRef href={WL.getRegisterLink()} className="btn-primary-simple" target={WL.regLinkTarget}>
        <FormattedMessage id="get_started" defaultMessage="Get Started" />
      </Link>
    </div>
    </div>
  </section>
)

export default EasyOperate;
