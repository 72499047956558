import React from 'react';
import YouTube from 'react-youtube';
import { useIntl } from 'react-intl';
import Modal from 'components/modal/modal';
import CssTransitionGroup from 'react-addons-css-transition-group';
import WL from 'constants/whiteLabel';
import '../../pages/customStyles.css';

const VideoModal = ({ opts, onReady, onClose, noAnimation = false }) => {
  const intl = useIntl();
  const key = WL.getHowItWorks(intl.locale, true);

  if (noAnimation) {
    return (
      <Modal onClose={onClose}>
        <YouTube
          videoId={key}
          id={key}
          opts={opts}
        />
      </Modal>
    )
  }

  return (
    <CssTransitionGroup
      transitionName="example"
      transitionAppear={true}
      transitionAppearTimeout={600}
      transitionEnter={true}
      transitionEnterTimeout={600}
      transitionLeave={true}
      transitionLeaveTimeout={600}
    >
      <Modal onClose={onClose}>
      <YouTube
        videoId={key}
        id={key}
        opts={opts}
        onReady={onReady}
      />
      </Modal>
    </CssTransitionGroup>
  );
}

export default VideoModal;
