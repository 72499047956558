import React, { Component } from 'react';
import cx from 'classnames';
import Img from 'components/Image';
import Link from 'components/Link';
import s from './SingleComment.module.css';

const MAX_HEIGHT = 120;

class SingleComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpandable: false,
      isExpanded: false,
    }

    this.text = React.createRef();
  }

  componentDidMount() {
    if (this.text.current.offsetHeight > MAX_HEIGHT) {
      this.setState({ isExpandable: true });
    }
  }

  handleMouseEnter = () => {
    this.setState({ isExpanded: true });
  }

  handleMouseLeave = () => {
    this.setState({ isExpanded: false });
  }

  renderContent(isExpanded) {
    const { avatar, href, name, nick, comment } = this.props;
    const { isExpandable } = this.state;

    return (
      <div className={cx(s.testimonial, { [s.expanded]: isExpanded })}>
        <div className={s.ava}><Img src={avatar} alt="avatar image"/></div>
        <div className={cx(s.user, {[s.empty]: !nick})}>
            { name }
        </div>
        { nick && !href && <div className={s.nick}>{nick}</div> }
        { href && <Link external href={href} className={s.nick}>{nick}</Link> }
        <div
          className={cx(s.text, { [s.textExpanded]: isExpanded, [s.expandable]: isExpandable })}
          ref={this.text}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          role="presentation"
        >
          {comment}
          <div className={cx(s.textBlur, { [s.expandableBlur]: isExpandable && !isExpanded })} />
        </div>
      </div>
    );
  }

  render () {
    const { up, down } = this.props;
    const { isExpanded } = this.state;

    return (
      <div className={cx(s.root, {[s.up]: up, [s.down]: down})}>
        {this.renderContent()}
        {isExpanded && this.renderContent(isExpanded)}
      </div>
    );
  }
}

export default SingleComment;
