import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Img from 'components/Image';
import Link from 'components/Link';
import WL from 'constants/whiteLabel';
import hexColoredSmall from "utils/images/hex-colored-small.svg"
import gs from 'pages/pages.module.css'
import s from 'pages/homeStyles.module.css'

const StayNotified = () => (
    <section className={cx(gs.content, s.hiveosContent, s.alternate)}>
        {
            WL.showHexImg ? (
                <img alt="hex" className={cx(s.drop1, s.__notifi)} src={hexColoredSmall} />
            ) : null
        }
        <div className={gs.backgroundHeader}>alerts</div>
        <div className={cx(gs.wrapper, s.wrapper)}>
        <div className={cx(gs.relative, s.notifiHover)}>
            <div className={cx(s.notifiToRight, s.notifi1)}>
                <Img src="stayNotifi1.png" className={cx(s.screen, gs.boxShadow)} alt="stay notified"/>
            </div>
            {
                WL.showHexImg ? (
                    <div className={cx(s.notifiToRight, s.notifi2)}>
                        <Img src="stayNotifi2.png" className={cx(s.screen, gs.boxShadow)} alt="stay notified"/>
                    </div>
                ) : null
            }
        </div>
        <h2>
            <FormattedMessage id="stay_notified" defaultMessage="Stay Notified" />
        </h2>
        <h3 className={gs.subheader}>
            <FormattedMessage id="stay_notified_notifications" defaultMessage="Notifications via Telegram and Discord" />
        </h3>
        <p>
            <FormattedMessage id="stay_notified_description" defaultMessage="Keep your team notified with our Smart Notifications. Distribute specific tasks to each team member individually. Stay informed on your system’s performance, temperature statuses, hashrate drops and much more" />
        </p>
        <div className={s.moreLinks}>
            <Link external noRef href={WL.getRegisterLink()} className="btn-primary-simple" target={WL.regLinkTarget}>
                <FormattedMessage id="switch_to_hive_os" defaultMessage="Switch to Hive OS" />
            </Link>
        </div>
        </div>
        <div className="get-started">
            <div className="cell">

            </div>
        </div>
    </section>
)

export default StayNotified;
