import React from 'react';
import Link from 'components/Link';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import gs from 'pages/pages.module.css'

const WatchDogs = () => (
    <section className={cx(gs.content, gs.accessContent)}>
        <div className={gs.wrapper}>
            <h2>
                <FormattedMessage id="keep_mining" defaultMessage="Keep mining at peak efficiency" />
            </h2>
            <h3 className={gs.subheader}>
                <FormattedMessage id="no_more_sag_with" defaultMessage="No more sag in profits with " />
                <Link withLang to="/features#watchdogs" className={gs.link}>
                    <FormattedMessage id="hashrate_watchdog" defaultMessage="hashrate watchdog" />
                </Link>
            </h3>
        </div>
    </section>  
)

export default WatchDogs;