import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import Img from 'components/Image';
import Link from 'components/Link';
import WL from 'constants/whiteLabel';
import hexColoredSmall from 'utils/images/hex-colored-small.svg';

import gs from 'pages/pages.module.css';
import s from 'pages/homeStyles.module.css';
import styles from './FindAllAnswers.module.css';

const kbImgLangMap = {
  'en': 'kb.png',
  'ru': 'kb_ru.png',
}

const FindAllAnswers = () => {
  const intl = useIntl();
  return (
    <section className={cx(gs.content, s.hiveosContent, styles.root)}>
      {
        WL.showHexImg ? (
          <img alt="hex" className={cx(s.drop1, s.__manage)} src={hexColoredSmall} />
        ) : null
      }
      <div className={cx(gs.backgroundHeader, styles.bg)}>answers</div>
      <div className={cx(gs.wrapper, s.wrapper)}>
        <div className={gs.relative}>
          <div className={s.transfromToLeft}>
            <Img src={kbImgLangMap[intl.locale] || kbImgLangMap['en']} className={cx(s.screen, styles.screen)}
                 alt="knowledge base" />
          </div>
        </div>
        <h2>
          <FormattedMessage id="find_answers" defaultMessage="Find all the answers" />
        </h2>
        <p>
          <FormattedMessage id="find_answers_description"
                            defaultMessage="Keep up with new features, mining tutorials, FAQ and other useful content in our base. Dive in mining world ." />
        </p>
        <div className={s.moreLinks}>
          <Link withLang to="/knowledge-base" className="btn-primary-simple">
            <FormattedMessage id="knowledge_base" defaultMessage="Knowledge Base" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default FindAllAnswers;
