import React, { Component } from 'react'
import Layout from 'components/layout'
import Cookies from 'js-cookie'
import _get from 'lodash/get';
import GetStarted from 'components/GetStarted';
import Bottom from 'components/Bottom/Bottom';
import Testimonials from 'components/Testimonials/testimonials';
import VideoModal from 'components/VideoModal';
import Intro from 'components/Home/Intro';
import OneSolution from 'components/Home/OneSolution';
import BeInControl from 'components/Home/BeInControl';
import EasyOperate from 'components/Home/EasyOperate';
import StayNotified from 'components/Home/StayNotified';
import FindAllAnswers from 'components/Home/FindAllAnswers';
import MobileApp from 'components/Home/MobileApp';
import WatchDogs from 'components/Home/WatchDogs';
import WL from 'constants/whiteLabel';
import './customStyles.css';

class IndexPage extends Component {
  state = {
    isShowModal: false,
    animateHowItWork: false,
    noAnimation: false,
  };

  openModal = () => {
    this.setState({
      animateHowItWork: !this.state.animateHowItWork
    })

    setTimeout(()=>{
      this.setState({
        isShowModal: !this.state.isShowModal
      })
      this.onPlay();
    }, 600);
  }

  closeModal = () => {
    this.setState({
      isShowModal: !this.state.isShowModal
    })

    setTimeout(()=>{
      this.setState({
        animateHowItWork: !this.state.animateHowItWork
      })
    }, 200);

  }

  _onReady = (event) => {
    setTimeout(() => {
      event.target.playVideo();
    }, 400);
  }

  render() {

    const {
      isShowModal,
      animateHowItWork
    } = this.state;

    let innerWidth = null;

    const windowGlobal = typeof window !== 'undefined' && window

    if (windowGlobal) {
      innerWidth  = windowGlobal.innerWidth;
    }

    if ( this.props.location.search ) {
      const REF_ID = 'id_referral'
      const ref = new URLSearchParams(this.props.location.search).get('ref');
      const isRefExistAndNotSet = ref !== null && Cookies.get(REF_ID) === undefined
      if (isRefExistAndNotSet) {
        Cookies.set('id_referral', ref, {expires: 365, path: '/', domain: '.hiveos.farm'});
      }
    }

    let opts = {
      height: 450,
      width: 800,
    };

    if ( innerWidth < 768 ) {
      opts = {
        height: 320,
        width: 320,
      }
    }

    return (
      <Layout lang={_get(this.props, 'pageContext.lang', 'en')}>
        {isShowModal && (
          <VideoModal
            opts={opts}
            onReady={this._onReady}
            onClose={this.closeModal}
            noAnimation={this.state.noAnimation}
          />
        )}
        <Intro
          openModal={this.openModal}
          animateHowItWork={animateHowItWork}
        />
        <div>
          <OneSolution />
          <BeInControl />
          <EasyOperate />
          {WL.hasTestimonials() && <StayNotified />}
          {WL.hasKb() && <FindAllAnswers />}
          {WL.hasApp() && <MobileApp />}
          {WL.hasTestimonials() && <WatchDogs/>}
          {WL.hasTestimonials() && <Testimonials/>}
          {WL.hasTestimonials() && <GetStarted/>}
          <Bottom/>
        </div>
      </Layout>
    )
  }

}

export default IndexPage;
