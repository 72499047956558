import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import WL from 'constants/whiteLabel';
import Img from 'components/Image';
import Link from 'components/Link';
import hexColoredSmall from "utils/images/hex-colored-small.svg"
import gs from 'pages/pages.module.css';
import s from 'pages/homeStyles.module.css';

const BeInControl = () => (
    <section className={cx(gs.content, s.hiveosContent, s.alternate)}>
        {
            WL.showHexImg ? (
                <img alt="hex" className={cx(s.drop1, s.__beInControl)} src={hexColoredSmall} />
            ) : null
        }
        <div className={gs.backgroundHeader}>monitor</div>
        <div className={cx(gs.wrapper, s.wrapper)}>
        <div className={gs.relative}>
            <div className={s.transfromToRight}>
                <Img src="screens/beInControl.png" className={s.screen} alt="be in control image"/>
            </div>
        </div>
        <h2 className={gs.msg}>
            <FormattedMessage id="everything_is_at_your_fingertips" defaultMessage="Everything is at your fingertips" />
        </h2>
        <p>
            <FormattedMessage id="be_in_control_description" defaultMessage="Monitor your rigs from a single dashboard. Keep track of hashrates, online statuses, GPU errors, team activity, pool configurations, power consumption, remote access from anywhere across the globe, troubleshoot and reboot GPUs remotely or perform bulk updates across your entire farm" />
        </p>
        <div className={s.moreLinks}>
            <Link external noRef href={WL.getRegisterLink()} className="btn-primary-simple" target={WL.regLinkTarget}>
                <FormattedMessage id="create_account" defaultMessage="Create Account" />
            </Link>
        </div>
        </div>
    </section>
)

export default BeInControl;
